<template>
<div class="container-fluid">
<div class="jumbotron jumbonet">
    <h1><span class="white">Veuillez vous connecter</span></h1>
	<div class="well">
	<div class="row">
		<div class="col-md-offset-2 col-md-10">
			<p>&nbsp;</p>
		</div>
	</div>

	<div class="row">
		<div class="col-md-offset-2 col-md-3">
		<label for="user">Identifiant</label>
			<div class="input-group">
				<span class="input-group-addon"><i class="fa fa-user"></i></span>
				<input type="text" v-model="user" class="form-control" id="user" placeholder="Identifiant">
			</div>
		</div>

		<div class="col-md-offset-1 col-md-3">
			<label for="pass">Mot de passe</label>
			<div class="input-group">
				<span class="input-group-addon"><i class="fa fa-key"></i></span>
			<input type="password" v-model="pass" class="form-control" id="mail" placeholder="Mot de passe">
      </div>
		</div>
	</div>
	<br/>
	<div class="row">
		<div class="col-md-offset-2 col-md-3">
			<button type="button" class="btn btn-primary" @click="connect">Ok</button>
		</div>
	</div>
	<br/>
	<div class="alert alert-danger" v-if="err">
  	<strong><i class="fa fa-warning"></i> Erreur!</strong> Identifiant ou mot de passe incorrect.
	</div>
	</div>
</div>
</div>
</template>

<script>
import store from "../store";
import {loginApi} from '../lib/api.js'

export default {
    store: store,
    data () {
    return {
        err: false,
        user: '',
        pass: ''
        }
    },
    methods: {
        connect: function () {
        this.err = false
	    loginApi(this, this.user, this.pass).then(
            (response) => {
            if (response.data.error_number == 0) {
				let r = response.data
				let data = {token: response.data.token,typeUser: r.level ,idUser: r.id, nomUser: r.name, piste: r.piste, nomPiste: r.nompiste, helistation: r.helistation, logo: r.logo, periode: r.periode}
				store.commit('connexion', data)
                this.$router.push({name: 'home'})
              } else {
              this.err = true
              }
            },
            (response) => {
            this.err = true
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/font-awesome64.css';
@import '../assets/scss/design.scss';

.jumbonet {
    background: url('../assets/img/piste.jpg');
}

.white {
    color: #FFF;
}

.btn-primary {
background-color: $back-color;
}
</style>
